import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import jsonp from 'jsonp'
import queryString from 'query-string'

export class GroupForm extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      email: '',
      fname: '',
      lname: '',
      currentDebt: '',
      annualIncome: '',
      financialGoal: '',
      savings: '',
      property: '',
      shares: '',
      business: '',
      password: '',
      formErrors: {email: ''},
      emailValid: false,
      passwordValid: false,
      formValid: false,
      isError: false,
      isDone: false,
      isExpanded: false
    }
  }

  handleUserInput (e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value}, () => { this.validateField(name, value) });
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;
    let passwordValid = this.state.passwordValid;

    switch(fieldName) {
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? '' : ' is invalid';
        break;
      case 'password':
        passwordValid = value.length >= 6;
        fieldValidationErrors.password = passwordValid ? '': ' is too short';
        break;
      default:
        break;
    }
    this.setState({formErrors: fieldValidationErrors,
                    emailValid: emailValid,
                    passwordValid: passwordValid
                  }, this.validateForm);
  }

  validateForm() {
    this.setState({formValid: this.state.emailValid});
  }

  errorClass(error) {
     return(error.length === 0 ? '' : 'has-error');
  }

  handleToggle(e){
    this.setState({ isExpanded: true });
    e.preventDefault();
  }

  submitForm() {
    // console.log(this.state);
    this.setState({isError:false});
    this.setState({isDone:false});

    const formData = {
      EMAIL: this.state.email,
      FNAME: this.state.fname,
      FDEBT: this.state.currentDebt,
      FINCOME: this.state.annualIncome,
      FGOAL: this.state.financialGoal,
      FSAVINGS: this.state.savings,
      FPROPERTY: this.state.property,
      FSHARES: this.state.shares,
      FBUSINESS: this.state.business
    };

    console.log(formData);

    jsonp(`https://herfinancialjourney.us3.list-manage.com/subscribe/post-json?u=9fea213a4ebed53107f6e118c&amp;id=cf408b82a2&${queryString.stringify(formData)}`, { param: 'c' }, (err, data) => {
      if (data && data.result == "error") {
        this.setState({isError:true});
      } else {
        this.setState({isError:false});
        this.setState({isDone:true});
      }
      console.log('err:', err);
      console.log('data:', data);
    });

  }


  render() {
  return (
    <signup>

    <div id="mc_embed_signup">

    <div className={`mc-field-group ${this.errorClass(this.state.formErrors.email)}`}>
      <label for="email">Email <span class="asterisk">*</span>
    </label>
      <input type="email" name="email" id="email" value={this.state.email} onChange={(event) => this.handleUserInput(event)}/>
    </div>

    <div className={`mc-field-group`}>
      <label for="fname">First Name <span class="asterisk">*</span>
    </label>
      <input name="fname" id="fname" placeholder="Your first name" value={this.state.fname} onChange={(event) => this.handleUserInput(event)}/>
    </div>

    <div className="clear">
    {
      this.state.isError && (
        <div className="form-error">Sorry, there was a problem.</div>
      )
    }

    {
      !this.state.isDone && (
        <button id="mc_embed_signup" type="submit" name="subscribe" className="button" disabled={!this.state.formValid} onClick={()=>this.submitForm()}>Sign up</button>
      )
    }
    {
      this.state.isDone && (
        <div className="form-success">Thanks for subscribing!</div>
      )
    }
    </div>

    </div>

    </signup>
  )}

}

export default GroupForm
