import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Header from "../components/header"

import SignupForm from "../components/signupform"
import GroupForm from "../components/groupform"

export class Group extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      isDone: true
    }
  }

  handleClick(e){
    this.setState({ isDone: true });
    e.preventDefault();
  }


  render() {

  const bannerStyle = {
      color: 'white',
      backgroundImage: 'url(/group_women.jpg)',
      padding: '16rem 0rem',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top center'
  };

  return (

  <Layout>
    <SEO
        title="Monthly Money Meets"
        description="Join a small tight-knit group of women who are interested in improving their financial lives. The group will meet virtually on a monthly basis to talk about all things money."
        image="/group_women_sq.jpg"
    />
    <Header/>

    <div class="section groupheader">
      <div class="container">
      </div>
    </div>

    <div class="section groupsignup">
      <div class="container">
        <div class="row">
        <div class="two columns" style={{fontSize:'1px'}}>&nbsp;</div>
        <div class="eight columns">
          <h1>Join Our Monthly Money Meets</h1>


          <p>
          We’re looking to form a small tight-knit group of women who are
          interested in improving their financial lives. The group will
          meet virtually on a monthly basis to talk about all things money.
          </p>

          <p>
          Our aim will be to support and encourage each other, improve our
          financial literacy, answer each other’s money questions and keep
          each other accountable, all with kindness and without judgemental or egos.
          </p>

            <p>
            Sign up with your email, and we&apos;ll send you an invitation to join our group shortly.
            </p>

            {
              this.state.isDone &&
              (
                <div>
                <GroupForm />
                </div>
              )

            }

            {
              !this.state.isDone &&
              (

                <div id="mc_embed_signup">
                <div className="clear">
                    <button id="mc_embed_signup" type="submit" name="subscribe" className="button" onClick={(event) => this.handleClick(event)}>I&apos;m interested!</button>
                </div>
                </div>
              )
            }

          </div>
        </div>
      </div>
    </div>


    <div class="section ">
      <div class="container">
        <div class="row">
          <div class="column">


          </div>
        </div>
      </div>
    </div>




  </Layout>

  )}
}

export default Group
